import React from "react"
import PropTypes from "prop-types"

const Html5 = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24"><title>Html5 icon</title><path d="M1.5 0h21l-1.91 21.563L11.977 24l-8.564-2.438L1.5 0zm7.031 9.75l-.232-2.718 10.059.003.23-2.622L5.412 4.41l.698 8.01h9.126l-.326 3.426-2.91.804-2.955-.81-.188-2.11H6.248l.33 4.171L12 19.351l5.379-1.443.744-8.157H8.531z" /></svg>
    )
}

Html5.propTypes = {
    color: PropTypes.string,
}

Html5.defaultProps = {
    color: "#000000",
}

export default Html5
