import React from "react"
import PropTypes from "prop-types"

const Medium = ({ color }) => {
    return (
        <svg
            xmlns xlink="http://www.w3.org/1999/xlink"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0.0 0.0 113.38582677165354 113.38582677165354"
            fill="none"
            stroke="none"
            stroke-linecap="square"
            stroke-miterlimit="10">
            <clipPath id="p.0">
                <path d="m0 0l113.385826 0l0 113.385826l-113.385826 0l0 -113.385826z"
                    clip-rule="nonzero" />
            </clipPath>
            <g clip-path="url(#p.0)">
                <path
                    fill="#000000"
                    fill-opacity="0.0"
                    d="m0 0l113.385826 0l0 113.385826l-113.385826 0z"
                    fill-rule="evenodd" />
                <path
                    fill="#4299e1"
                    d="m-4.2598424E-4 18.89762l0 0c0 -10.437085 8.46093 -18.898016 18.898016 -18.898016l75.5898 0l0 0c5.012062 0 9.818848 1.9910352 13.362915 5.535101c3.5440598 3.544065 5.535095 8.350849 5.535095 13.362915l0 75.58979c0 10.437088 -8.46093 18.898018 -18.89801 18.898018l-75.5898 0c-10.437087 0 -18.898016 -8.46093 -18.898016 -18.898018z"
                    fill-rule="evenodd" />
                <path
                    fill="#000000"
                    fill-opacity="0.0"
                    d="m-4.2598424E-4 16.978092l113.385826 0l0 79.40158l-113.385826 0z"
                    fill-rule="evenodd" />
                <path
                    fill="#ffffff"
                    d="m7.532331 85.478874l0 -56.874996l17.5 0q7.499998 0 13.421873 3.375q5.921875 3.375 9.234375 9.609375q3.328125 6.234375 3.328125 14.15625l0 2.625q0 7.921871 -3.265625 14.093746q-3.265625 6.171875 -9.203125 9.578125q-5.9375 3.390625 -13.390623 3.4375l-17.625 0zm11.71875 -47.39062l0 37.968746l5.671875 0q6.875 0 10.499998 -4.484375q3.640625 -4.5 3.71875 -12.859371l0 -3.0q0 -8.671875 -3.59375 -13.140625q-3.593748 -4.484375 -10.515623 -4.484375l-5.78125 0zm86.52344 47.39062l-11.71875 0l-22.8125 -37.42187l0 37.42187l-11.718754 0l0 -56.874996l11.718754 0l22.859375 37.499996l0 -37.499996l11.671875 0l0 56.874996z"
                    fill-rule="nonzero" />
            </g>
        </svg>
    )
}

Medium.propTypes = {
    color: PropTypes.string,
}

Medium.defaultProps = {
    color: "#000000",
}

export default Medium
